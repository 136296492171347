import './Header.css';

function Header() {
  
  return (
    <div className='header'>
      <div className="spoteet-icon" />
      <h1 className='title'>Spoteet</h1>
    </div>
  )

}

export default Header;