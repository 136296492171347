import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './pages/Home/HomePage';
import RegisteredPage from './pages/Registered/RegisteredPage';

function App() {

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, []);

  return (
    <div id='app'>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/registered" element={<RegisteredPage />} />
        </Routes>
      </Router>
    </div>
  );

}

export default App;
